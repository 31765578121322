"use client";

import { FullPageError } from "@carbon/ibm-products";
import { Button } from "@carbon/react";
import { useEffect } from "react";

import * as Sentry from "@sentry/browser";
// Initialize Sentry
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  beforeSend(event) {
    event.extra = {
      ...event.extra,
      runtime: "browser",
      url: window.location.href,
      userAgent: navigator.userAgent,
    };
    return event;
  },
  integrations: [],
});

export default function Error({
  error,
  reset,
}: {
  error: Error & {
    digest?: string;
    cause?: {
      title?: string;
      description?: string;
      kind?: string;
    };
  };
  reset: () => void;
}) {
  useEffect(() => {
    // Send error to Sentry
    Sentry.withScope((scope) => {
      scope.setTag("page", "error.tsx");
      scope.setExtra("errorInfo", {
        name: error.name,
        message: error.message,
        stack: error.stack,
        digest: error.digest,
      });
      Sentry.captureException(error);
    });
  }, [error]);

  return (
    <FullPageError
      description={
        error.cause?.description ||
        "Sorry, something went wrong while loading this page. Please try again or contact support if the problem persists."
      }
      kind={error.cause?.kind || "custom"}
      label="Page Error"
      title={error.cause?.title || "Unable to Load"}
    >
      <Button onClick={reset}>Try again</Button>
    </FullPageError>
  );
}
